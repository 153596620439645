import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    primeletter: string;
    uidvendedor: any;
    uidfranqueado: string;
    vend: any;
    fran: any;
    nomevendedor: any;

    constructor(
        private router: Router,
        private afs: AngularFirestore
    ) {
        
    }

    public DadosLogadoGlobal() {
        var user: any = getFirebaseBackend().getAuthenticatedUser();
        return user;
    }

    public PegaIdLogado() {
        var user: any = getFirebaseBackend().getAuthenticatedUser();

        var uid;
        if (user != null) {
            uid = user.uid;
            return uid;
        }
    }

    public PegaEmailLogado() {
        var user: any = getFirebaseBackend().getAuthenticatedUser();
        var email;
        if (user != null) {
            email = user.email;
            return email;
        }
    }


    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }


    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    //  register(email: string, password: string, name: string) {
    //     return getFirebaseBackend().registerUser(email, password, name).then((response: any) => {
    //          const user = response;
    //         return user;
    //     });
    // }
    UpdateUser(tipo){
    var user = firebase.auth().currentUser;
    user.updateProfile({  
        photoURL: tipo
    }).then(function() {  
        console.warn("OK");
      })
      .catch(function(error) {  // An error happened.
        console.error(error);
      });
    }

    /**
   * Registra o usuário com detalhes fornecidos 
   */
    registerUser(email, password, name, idparceiro) {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user: any) => {
                var user: any = firebase.auth().currentUser;
                resolve(user);

                /**
    * Cadastramos o usuario na collection empresas e usuários
    */
                var uid;
                if (user != null) {
                    uid = user.uid;
                    console.log(uid, name, email)
                    //this.CadastrarMaisDadosTabelaParceiros(uid, name, email, idparceiro);
                    
                    console.warn("aqui");
                } else {
                    console.warn('Não achamos o uid do recém datacadastro.')
                }
                //console.log(user);
            }, (error) => {
                reject((error));
            });
        });
    }

    /**
  * Cadastramos o usuario na collection parceiros e empresas
   */
    CadastrarMaisDadosTabelaParceiros(iduser, nomestr, emailstr, idparceiro) {
        let date = firebase.firestore.FieldValue.serverTimestamp();

        if (idparceiro === '' || idparceiro === null || idparceiro === undefined){
                //Aqui não temos o id do parceiro, então cadastramos como parceiro
                const collectione = firebase.firestore().collection('parceiros').doc(iduser);
                collectione.set({
                    nome: nomestr, 
                    status: 'Ativo',
                    datacadastro: date,
                    whatsapp: '',
                    cidade: '',
                    estado: '',
                    email: emailstr
                });
                this.UpdateUser("parceiro");
                this.router.navigate(['/pages/empresas']);
            }else{

                const collectione = firebase.firestore().collection('empresas').doc(iduser);
                collectione.set({
                    empresa: nomestr, 
                    status: 'Ativo', 
                    idparceiro: idparceiro, 
                    datacadastro: date,
                    whatsapp: '',
                    cidade: '',
                    estado: '',
                    apibc:   '',
                    email: emailstr
                });
                this.UpdateUser("empresa");
                this.router.navigate(['/pages/dashboard']);
            }
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }


}

