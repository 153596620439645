import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { Models } from '../models/models.model';
import { AuthenticationService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class ServicosService {
  public IdLogado: string;
  public dadosempresa: any;
  public EmailLogadoGlobal: string;
  public ipAddress: string;
  tipoarr: any;
  error: string;
  db: any;
  public rotaatual: string;
  public rotaatualbusca: string;
  dadosuser: any;
  NomeLogado: any;
  EmailLogado: any;
  status: any;
  public ApiBcLogado: any;
  public tipo: string;
  public TipoLogadoGlobal: any;
  public token: any;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private http: HttpClient,
    private afs: AngularFirestore,

    private Auth: AuthenticationService
  ) {
    this.IdLogado = Auth.PegaIdLogado();
    this.EmailLogadoGlobal = Auth.PegaEmailLogado();
    this.TipoLogadoGlobal = Auth.DadosLogadoGlobal().photoURL;
    this.db = firebase.firestore();
    console.warn(this.DadosLogado());
    //console.warn(this.ApiBcLogado);
    //this.DadosUsuarioLogado(this.IdLogado);
    this.getIPAddress();

    // console.log(this.authService.currentUser());
    this.PegaToken();
    this.routeEvent(this.router);
    //photoURL
  }


  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        console.log(e)
        this.rotaatual = e.url.substring(0, 11);
        this.rotaatualbusca = e.url;
        console.log(this.rotaatualbusca);
        if (this.rotaatualbusca == '/pages/list/1/1') {
          window.location.href = ('/pages/list/1/2');
        }
      }
    });

  }

  DadosLogado() {
    this.DadosUsuarioLogado().valueChanges().subscribe(res => {
      //console.log(res);
      this.dadosuser = res;
      //this.LogoLogado = this.dadosuser.logo;
      //this.NomeLogado = this.dadosuser.empresa;
      //this.EmailLogado = this.dadosuser.email;
      //this.status = this.dadosuser.status;
      //this.ApiBcLogado = this.dadosuser.apibc;
      //console.warn(this.ApiBcLogado);
    });

    //console.warn(this.IdLogado);
  }

  DadosUsuarioLogado() {
    return this.afs.collection('empresas').doc(this.IdLogado);
  }



  PegaToken() {
    this.db.collection('empresa')
      .where("id", "==", 'token1')
      .onSnapshot(
        querySnapshot => {
          let arrc = [];
          querySnapshot.forEach(function (doc) {
            var obj = JSON.parse(JSON.stringify(doc.data()));
            obj.id = doc.id;
            //obj.eventId = doc.id;
            arrc.push(obj);
          });
          console.log(arrc);
          this.token = arrc[0].token;

        },
        err => {
          console.log(`Error: ${err}`);
        }
      );

  }



  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      //console.log(res.ip);
    });
  }




}



