<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="25">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

    </div>

    <div class="d-flex">
      <!--
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="" src="assets/images/flags/us.jpg" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

         
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="assets/images/flags/spain.jpg" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">Spanish</span>
          </a>

         
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="assets/images/flags/germany.jpg" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">German</span>
          </a>

       
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="assets/images/flags/italy.jpg" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">Italian</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="assets/images/flags/russia.jpg" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">Russian</span>
          </a>
        </div>
      </div>
    -->


      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <div *ngIf="quantmsg == 0; else maisdeum">
            <i class="bx bx-bell"></i>
            <span class="badge badge-success badge-pill">{{quantmsg}}</span>
          </div>
          <ng-template #maisdeum>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge badge-danger badge-pill">{{quantmsg}}</span>
          </ng-template>

        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>

          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <div *ngIf=(messagee)>
              <div class="alert-danger" style="white-space: pre-wrap;text-align: center;">
                {{messagee}}
              </div>
            </div>


          </perfect-scrollbar>

        </div>
      </div> -->

       <div class="dropdown d-inline-block" ngbDropdown *ngIf="emaillogado == 'contato@timona.com.br';">
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">

          <span class="d-none d-xl-inline-block ml-1">Configurações</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        
          <a class="dropdown-item" href="./pages/dados"><i class="bx bx-user font-size-16 align-middle mr-1"></i>
            Dados da aplicação</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
        </div>
      </div>


    </div>
  </div>
</header>