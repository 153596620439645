import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Menu',
        isTitle: true
    },
    {
        id: 7,
        label: 'Dashboard',
        icon: 'bxs-home',
        link: '/pages/dashboard/h',
    },
    {
        id: 8,
        label: 'Buscar pedidos',
        icon: 'bxs-group',
        link: '/pages/list/1/1',
    },
    {
        id: 8,
        label: 'Pedidos',
        icon: 'bxs-group',
        link: '/pages/pedido',
    },
    {
        id: 38,
        label: 'Sair',
        icon: 'bxs-exit',
        link: '/logout',

    }
];

export const MENUP: MenuItem[] = [
    {
        id: 1,
        label: 'Menu',
        isTitle: true
    },
    {
        id: 7,
        label: 'Empresas',
        icon: 'bxs-home',
        link: '/pages/empresas',
    },
    {
        id: 8,
        label: 'Ajuda',
        icon: 'bxs-help-circle',
        link: '/pages/ajuda',
    },
    {
        id: 8,
        label: 'Meus Dados',
        icon: 'bxs-user-circle',
        link: '/pages/dados',
    },
    {
        id: 38,
        label: 'Sair',
        icon: 'bxs-exit',
        link: '/logout',

    }
];

